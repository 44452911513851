/**
 *
 * Project settings - override any values from platform settingsDefaults here
 *
 */

import settingsDefault from '_platform/src/theme/settingsDefault';

const settings = {
  // -- Base Settings --
  siteName: 'Fujifilm X Rewards',

  // -- Cloudinary Settings --
  // Cloudinary cloud name
  cloudinaryCloudName: 'incremental',

  // -- Rewards --
  // Hide reward category images (main rewards page)
  // rewardsCategoryHideImage: true,

  // Pages - must have trailing slash
  cartPage: '/cart/',
  privacyPage: '/privacy/',
  rewardsPage: '/rewards/',

  // Delivery method "Client" label (usually Branch or Rep)
  deliveryMethodClientLabel: 'Rep',

  // Master override settings
  // cartDisabled: true,
  // quickviewDisabled: true,
  // wishlistDisabled: true,

  // -- Default Overrides --

  // Important!: If the user is being redirected, ensure that the PrivateRoute
  // component has the `tempAuthAllowed` prop set, otherwise the user will not
  // be able to see/update their profile.
  // For futher documentation, see _platform/src/theme/settingsDefault.js
  // redirectOnIncompleteProfile: '/profile/',

  // Rewards page - disable category listing and display rewards on the main page
  // rewardsCategorySkip: true,
};

// Note: If you are altering the breakpoints, make sure you loop over the
// values and create the media prop. See settingsDefault.js for implementation.

// Deep merge with default values
const settingsApp = { ...settingsDefault, ...settings };

export default settingsApp;
